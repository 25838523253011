<template>
  <div class="user-audit">
    <div class="container-md">
      <div class="col-12 sub-title">
        <h5>
          {{ $t('historySubTitle') }}
        </h5>
      </div>
      <ul class="list-group list-group-flush">
        <li v-for="(event, i) in events" class="list-group-item pl-0" :key="i">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <label>{{ $t('eventDate') }}: </label
              ><span>{{ event.eventDate }} </span>
            </div>
            <div class="col-sm-12 col-md-6">
              <label>{{ $t('changedField') }}: </label
              ><span>{{ event.changedField }} </span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <label>{{ $t('valueBeforeChange') }}: </label
              ><span>{{ event.valueBeforeChange }} </span>
            </div>
            <div class="col-sm-12 col-md-6">
              <label>{{ $t('valueAfterChange') }}: </label
              ><span>{{ event.valueAfterChange }} </span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <label>{{ $t('userMakingChange') }}: </label
              ><span>{{ event.userMakingChange }} </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getUserAudit as _getUserAudit } from '@/services/UserService';

export default {
  name: 'UserAudit',
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      events: [],
    };
  },
  mounted() {
    setTimeout(() => this.loadHistory(this.userId), 300);
  },
  methods: {
    loadHistory() {
      _getUserAudit(this.userId)
        .then((response) => {
          this.events = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>

<style lang="scss" scoped>
.user-audit label {
  font-weight: bold;
}
.user-audit span {
  margin-left: 5px;
  color: #ff8105;
  font-weight: bold;
}
.user-audit h6 {
  color: #ff8105;
}
.user-audit .sub-title {
  color: #ff8105;
  margin-bottom: 1em;
  border-bottom: 2px solid #c9c2c2;
  padding-bottom: 0.25em;
}
.user-audit .list-group {
  max-height: 500px;
  margin-bottom: 10px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.user-audit .list-group-flush > .list-group-item {
  border-width: 1px;
  margin-bottom: 0.5em;
  padding: 1em !important;
  border-radius: 4px;
}
</style>