<template>
  <div>
    <content-header :title="$t('usersMaintenance')" />
    <div class="container-fluid">
      <validation-observer ref="createValidator" novalidate>
        <b-container class="main-page-container" fluid>
          <b-row>
            <sub-header :title="$t('filterusers')" />
          </b-row>
          <filter-manager
            ref="filterComponent"
            v-model="selectedFilters"
            class="mb-3"
            :display-advanced="false"
            :filters="filtersConfig"
            :search-text="$t('filter')"
            :export-text="$t('export')"
            :showExport="true"
            @search="getUsers(1)"
            @export="getUserDataDocument()"
          />
        </b-container>
        <separator
          class="mt-4 mb-3 pl-0 pr-0"
          content-class="separator-custom-content"
          :text="$t('userslist')"
        />
        <b-row class="mt-4 mb-3">
          <b-col sm="12">
            <b-button
              class="float-right"
              variant="primary"
              @click="goToCreateUser"
            >
              <i class="fas fa-plus" /> {{ $t('create') }}
            </b-button>
            <b-button
              class="float-right mr-2"
              variant="outline-warning"
              @click="goToMassCreateUsers"
            >
              <i class="fal fa-upload" /> {{ $t('massCreateUsers') }}
            </b-button>
          </b-col>
        </b-row>
        <CustomNotFound
          v-if="filtered && !users.data.length"
          :text="$t('noUserFoundForFilter')"
        />
        <b-row v-if="users.data.length" fluid>
          <b-col
            v-for="(user, index) in users.data"
            :key="index"
            class="mt-3"
            cols="12"
          >
            <search-result
              :value="user"
              @assign="assignLocation(user)"
              @assign-appointment="assignAppointmentLocation(user)"
              @assign-permissions="addPermissions(user)"
              @change="changeUserRole(user)"
              @edit="editUser(user)"
              @block="lockOrUnlock(user.userId)"
              @history="history(user)"
            />
          </b-col>
        </b-row>
        <custom-pagination
          v-if="users.count > 0"
          id="pgPager"
          :data="users"
          :page-size="pageSize"
          @pagination-go-page="getUsers($event)"
          @pagination-rows-per-page="getUsers(pageIndex, $event)"
        />
        <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      </validation-observer>
    </div>
    <custom-modal v-model="showAssignOfficeModal" size="lg">
      <AssignOfficesUser
        v-if="showAssignOfficeModal"
        :user="user"
        @close="showAssignOfficeModal = false"
        @on-saved="onAssignOfficesUserSaved"
      />
    </custom-modal>
    <custom-modal v-model="showAssignAppointmentOfficeModal" size="lg">
      <AssignAppointmentLocations
        v-if="showAssignAppointmentOfficeModal"
        :user="user"
        @close="showAssignAppointmentOfficeModal = false"
        @on-saved="onAssignOfficesUserSaved"
      />
    </custom-modal>
    <custom-modal
      id="user-mass-create-modal"
      ref="user-mass-create-ref"
      size="lg"
    >
      <MassCreateUsers @close="$refs['user-mass-create-ref'].hide()" />
    </custom-modal>
    <custom-modal
      v-model="showHistoryModal"
      size="lg"
      :title="$t('historyTitle')"
    >
      <UserAudit
        v-if="showHistoryModal"
        :userId="user.userId"
        @close="showHistoryModal = false"
      />
    </custom-modal>
  </div>
</template>

<script>
import sessionState from '@/mixins/sessionState';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult';
import {
  getUsers as _getUsers,
  lockOrUnlock as _lockOrUnlock,
  getUserDataDocument as _getUserDataDocument,
} from '@/services/UserService';
import { getRoleList as _getRoleList } from '@/services/RoleService';
import FilterManager from '@/components/FilterManager';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import CustomPagination from '@/components/CustomPagination.vue';
import contentHeader from '@/components/Header';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SubHeader from '@/components/SubHeader.vue';
import SystemRoles from '@/constants/SystemRoles';
import AssignOfficesUser from '@/components/AssignOfficesUser.vue';
import MassCreateUsers from './components/MassCreateUsers.vue';
import UserAudit from './components/UserAudit.vue';
import _generalApi from '@/modules/app/api.js';
import AssignAppointmentLocations from './components/AssignAppointmentLocations.vue';

export default {
  name: 'UsersList',
  components: {
    SearchResult,
    FilterManager,
    CustomPagination,
    Separator,
    contentHeader,
    CustomNotFound,
    SubHeader,
    CustomModal,
    AssignOfficesUser,
    MassCreateUsers,
    UserAudit,
    AssignAppointmentLocations
  },
  data() {
    return {
      filters: {
        locations: [],
        companies: [],
        roles: [],
      },
      selectedFilters: {
        role: [],
        location: [],
        company: [],
        user: null,
        hideInactiveUsers: false,
      },
      selectedUserId: null,
      users: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      isCompanyDisabled: false,
      isLocationDisabled: false,
      pageSize: 10,
      filtered: false,
      noResultsFound: false,
      user: null,
      pageIndex: 1,
      showAssignOfficeModal: false,
      showHistoryModal: false,
      showAssignAppointmentOfficeModal: false,
    };
  },
  mixins: [sessionState],
  computed: {
    filtersConfig() {
      return [
        {
          name: 'role',
          label: this.$t('roles'),
          component: 'FilterMultiSelect',
          options: this.filters.roles,
          isMultiple: true,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-xs-12 col-sm-12 col-lg-2',
          selectable: this.isRoleEnabled,
          initialValue: this.selectedFilters.role,
          onChanged: this.onRoleChanged,
        },
        {
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          disabled: this.isCompanyDisabled,
          options: this.filters.companies,
          placeholder: this.companiesPlaceholder,
          customErrorMsg: 'required',
          fieldtext: 'text',
          error: this.$t('mustselectcompany'),
          fieldvalue: 'value',
          clearable: true,
          class: 'col-xs-12 col-sm-6 col-lg-3',
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
        {
          name: 'location',
          label: this.$t('localization'),
          component: 'FilterMultiSelect',
          error: this.$t('mustselectlocalization'),
          disabled: this.isLocationDisabled,
          options: this.filters.locations,
          placeholder: this.locationsPlaceholder,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-xs-12 col-sm-6 col-lg-3',
          initialValue: this.selectedFilters.location,
          onChanged: this.onLocationChanged,
        },
        {
          name: 'user',
          label: this.$t('userEmail'),
          component: 'BaseInput',
          placeholder: this.$t('userEmail'),
          class: 'col-xs-12 col-sm-6 col-lg-4',
          initialValue: this.selectedFilters.user,
          value: this.selectedFilters.user,
          error: this.$t('mustBeAtLeast2Chars'),
          rules:
            this.selectedFilters.user && this.selectedFilters.user.length == 1
              ? { min: 2 }
              : {},
        },
        {
          name: 'hideInactiveUsers',
          label: this.$t('hideInactiveUsersFilter'),
          component: 'BaseSwitch',
          class: 'col-xs-12 col-sm-6 col-lg-4',
          initialValue: this.selectedFilters.hideInactiveUsers,
          value: this.selectedFilters.hideInactiveUsers,
        },
      ];
    },
    companiesPlaceholder() {
      return `-- ${this.$t(this.isCompanyDisabled ? 'noCompany' : 'all')} --`;
    },
    locationsPlaceholder() {
      return `-- ${this.$t(this.isLocationDisabled ? 'noOffice' : 'all')} --`;
    },
  },
  methods: {
    async loadInitialFilters() {
      await _getRoleList()
        .then((response) => {
          let roles = response.data || [];

          this.filters.roles = roles;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];

          if (response.data.length == 1) {
            this.getAvailableLocalizations(
              this.selectedFilters.company[0].value,
            );
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onAssignOfficesUserSaved() {
      if (
        this.selectedFilters.company.length &&
        this.selectedFilters.location.length
      ) {
        this.getUsers(this.users.currentPage);
      }
    },
    isRoleEnabled(role) {
      if (this.selectedFilters.role.length == 0) return true;
      const haveNoOfficeRole = this.selectedFilters.role.some(
        (x) => !x.hasOffice,
      );
      if (haveNoOfficeRole)
        return (
          !role.hasOffice || role.id === SystemRoles.localuseradministrator
        );

      return role.hasOffice || role.id === SystemRoles.localuseradministrator;
    },
    onRoleChanged(value) {
      this.clearUser();
      const haveNoOfficeRole = value.some((x) => !x.hasOffice);

      if (haveNoOfficeRole) {
        this.selectedFilters.company = [];
        this.selectedFilters.location = [];
      }

      this.isCompanyDisabled = this.isLocationDisabled = haveNoOfficeRole;

      this.selectedFilters.role = value ? value : [];
    },
    onCompanyChanged(value) {
      this.clearUser();
      this.selectedFilters.location = [];
      this.filters.locations = [];

      if (value) {
        this.selectedFilters.company = [value];

        this.getAvailableLocalizations(value.value);
      } else {
        this.selectedFilters.company = [];
      }
    },
    onLocationChanged(value) {
      this.clearUser();
      this.selectedFilters.location = value ? [value] : [];
    },
    clearUser() {
      this.users = {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      };
      this.filtered = false;
    },
    async getUsers(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        roleIds:
          this.selectedFilters.role.length > 0
            ? this.selectedFilters.role.map((x) => x.id)
            : null,
        companyId:
          this.selectedFilters.company.length > 0
            ? this.selectedFilters.company[0].value
            : null,
        locationConfigurationId:
          this.selectedFilters.location.length > 0
            ? this.selectedFilters.location[0].id
            : null,
        user: this.selectedFilters.user || null,
        isActive: this.selectedFilters.hideInactiveUsers
          ? this.selectedFilters.hideInactiveUsers
          : null,
        isApproved: this.selectedFilters.hideInactiveUsers
          ? this.selectedFilters.hideInactiveUsers
          : null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _getUsers(payload)
        .then(({ data }) => {
          this.users = data;
          this.filtered = true;

          this.noResultsFound = this.users.length == 0;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async goToCreateUser() {
      // Persist current state of this view
      this.saveSessionData('usersData', Object.assign({}, this.$data));

      this.$router.push({ name: 'userCreate' });
    },
    goToMassCreateUsers() {
      this.$refs['user-mass-create-ref'].show();
    },
    assignLocation(user) {
      this.user = user;
      this.showAssignOfficeModal = true;
    },
    assignAppointmentLocation(user) {
      this.user = user;
      this.showAssignAppointmentOfficeModal = true;
    },
    editUser(user) {
      // Persist current state of this view
      this.saveSessionData('usersData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'userEdit',
        params: {
          id: user.userId,
        },
      });
    },
    addPermissions(user) {
      this.saveSessionData('usersData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'userEditPermissions',
        params: { id: user.userId },
      });
    },
    changeUserRole(user) {
      // Persist current state of this view
      this.saveSessionData('usersData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'userChangeRole',
        params: {
          id: user.userId,
        },
      });
    },
    lockOrUnlock(userId) {
      _lockOrUnlock(userId)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.getUsers();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getUserDataDocument() {
      const payload = {
        roleIds:
          this.selectedFilters.role.length > 0
            ? this.selectedFilters.role.map((x) => x.id)
            : null,
        companyId:
          this.selectedFilters.company.length > 0
            ? this.selectedFilters.company[0].value
            : null,
        locationConfigurationId:
          this.selectedFilters.location.length > 0
            ? this.selectedFilters.location[0].id
            : null,
        user: this.selectedFilters.user || null,
      };

      // At least 2 parameters must be selected
      var cantFilter = 0;
      if (payload.roleIds) cantFilter++;
      if (payload.roleIds && payload.roleIds.length > 1) cantFilter++;
      if (payload.companyId) cantFilter++;
      if (payload.locationConfigurationId) cantFilter++;
      if (payload.user) cantFilter++;

      if (cantFilter < 2) {
        this.ShowToast(
          'Error',
          `${this.$t('userCompleteParameterInformation')}`,
          'error',
        );
        return;
      }

      await _getUserDataDocument(payload)
        .then((response) => _generalApi.ConvertToBlob(response.data))
        .catch((error) => {
          if (error.response.data.message) {
            this.ShowToast('Error', error.response.data.message, 'error');
          } else {
            this.ShowToast('Error', error.message, 'error');
          }
        });
    },
    history(user) {
      this.user = user;
      this.showHistoryModal = true;
    },
  },
  async mounted() {
    // load current state of this view
    const previousStateData = this.loadAndRemoveSessionData('usersData');

    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      this.loadInitialFilters();
    }
    this.clearUser();

    if (
      this.selectedFilters.role.length ||
      this.selectedFilters.location.length ||
      this.selectedFilters.company.length ||
      this.selectedFilters.user
    )
      this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .separator-custom.content {
  margin-right: 0;
  margin-left: 0;
}
</style>
