<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <div class="row">
            <sub-header :title="$t('userinformation')" />
            <div class="col-md-12">
              <validation-observer
                ref="userForm"
                class="row"
                novalidate
                tag="section"
              >
                <validation-provider
                  v-if="isEditMode || isChangeRoleMode"
                  v-slot="{ errors }"
                  class="col-md-6"
                  tag="div"
                >
                  <base-input
                    v-model="model.userId"
                    :disabled="true"
                    :error="errors[0]"
                    :label="$t('userIdentifier')"
                  />
                </validation-provider>
                <div v-show="isEditMode || isChangeRoleMode" class="col-md-6" />
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  rules="required"
                  tag="div"
                >
                  <base-input
                    v-model="model.userName"
                    :disabled="isChangeRoleMode"
                    :error="errors[0]"
                    :error-msg="$t('usernamerequired')"
                    :label="$t('userName')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  :rules="{
                    required: passwordRequired,
                  }"
                  tag="div"
                >
                  <base-input
                    v-model="model.password"
                    :disabled="isChangeRoleMode"
                    :error="errors[0]"
                    :error-msg="$t('passwordrequired')"
                    :label="$t('password')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  rules="required"
                  tag="div"
                >
                  <base-input
                    v-model="model.firstName"
                    :disabled="isChangeRoleMode"
                    :error="errors[0]"
                    :error-msg="$t('namerequired')"
                    :label="$t('name')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  rules="required"
                  tag="div"
                >
                  <base-input
                    v-model="model.paternalLastName"
                    :disabled="isChangeRoleMode"
                    :error="errors[0]"
                    :error-msg="$t('paternalLastNameRequired')"
                    :label="$t('paternalLastName')"
                  />
                </validation-provider>
                <div class="col-md-6">
                  <base-input
                    v-model="model.maternalLastName"
                    :disabled="isChangeRoleMode"
                    :label="$t('maternalLastName')"
                  />
                </div>

                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  rules="required"
                  tag="div"
                >
                  <filter-select
                    v-model="model.roleId"
                    :clearable="false"
                    :error="errors[0]"
                    :error-msg="$t('mustselectrole')"
                    fieldtext="name"
                    fieldvalue="id"
                    :label="$t('roles')"
                    :options="roles"
                    :placeholder="$t('select')"
                  />
                </validation-provider>
                <validation-provider
                  v-if="showLocationForm"
                  v-slot="{ errors }"
                  class="col-md-6"
                  :rules="{
                    required: model.roleId != localUserAdministrator,
                  }"
                  tag="div"
                >
                  <filter-select
                    v-model="model.companyId"
                    :clearable="
                      model.roleId == localUserAdministrator &&
                      !isChangeRoleMode
                    "
                    :error="errors[0]"
                    :error-msg="$t('mustSelectOne')"
                    fieldtext="text"
                    fieldvalue="value"
                    :label="$t('company')"
                    :options="collections.companies"
                    :placeholder="$t('select')"
                    @on-selection-changed="onCompanyChange()"
                  />
                </validation-provider>
                <validation-provider
                  v-if="showLocationForm"
                  v-slot="{ errors }"
                  class="col-md-6"
                  :rules="{
                    required:
                      model.roleId != localUserAdministrator ||
                      !!model.companyId,
                  }"
                  tag="div"
                >
                  <filter-select
                    v-model="model.locationConfigurationId"
                    :clearable="
                      model.roleId == localUserAdministrator &&
                      !isChangeRoleMode
                    "
                    :disabled="!model.companyId"
                    :error="errors[0]"
                    :error-msg="$t('mustSelectOne')"
                    fieldtext="name"
                    fieldvalue="id"
                    :label="$t('localization')"
                    :options="collections.locations"
                    :placeholder="$t('select')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  rules="required"
                  tag="div"
                >
                  <filter-select
                    v-model="model.securityProfileId"
                    :clearable="false"
                    :disabled="isChangeRoleMode"
                    :error="errors[0]"
                    :error-msg="$t('mustselectsecurityprofile')"
                    fieldtext="name"
                    fieldvalue="id"
                    :label="$t('securityProfile')"
                    :options="collections.securityProfiles"
                    :placeholder="$t('select')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  rules="required|email"
                  tag="div"
                >
                  <base-input
                    v-model="model.email"
                    :disabled="isChangeRoleMode"
                    :error="errors[0]"
                    :error-msg="$t('mustentercorrectemail')"
                    :label="$t('email')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  :rules="{ required: !!model.phoneProviderId }"
                >
                  <base-input
                    v-model="model.phone"
                    :disabled="isChangeRoleMode"
                    :error="errors[0]"
                    :error-msg="$t('phoneRequired')"
                    :label="$t('phone')"
                    mask-type="Phone"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  class="col-md-6"
                  :rules="{ required: !!model.phone }"
                >
                  <filter-select
                    v-model="model.phoneProviderId"
                    :disabled="isChangeRoleMode"
                    :error="errors[0]"
                    :error-msg="$t('mustSelectOne')"
                    fieldtext="text"
                    fieldvalue="value"
                    :label="$t('phoneProvider')"
                    :options="collections.phoneProviders"
                    :placeholder="$t('select')"
                  />
                </validation-provider>
                <validation-provider class="col-md-6">
                  <base-input
                    v-model="model.specialId"
                    :disabled="isChangeRoleMode"
                    :label="$t('specialid')"
                  />
                </validation-provider>
                <validation-provider class="col-md-2">
                  <base-input
                    v-model="model.virtualPhoneExtension"
                    :disabled="isChangeRoleMode || !haveCommunicationModule"
                    :label="$t('virtualPhoneExtension')"
                  />
                </validation-provider>
              </validation-observer>
            </div>
            <sub-header :title="$t('userParameters')" />
            <div class="col-md-12">
              <div class="row">
                <div class="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
                  <p class="m-0">
                    {{ $t('tempPassword') }}
                  </p>
                  <b-form-checkbox
                    v-model="model.hasTempPassword"
                    :disabled="isChangeRoleMode"
                    size="lg"
                    switch
                  />
                </div>
                <div
                  v-if="showExtraParameters"
                  class="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4"
                >
                  <p class="m-0">
                    {{ $t('receiveappointmentconfirmation') }}
                  </p>
                  <b-form-checkbox
                    v-model="model.canReceiveappointmentconfirmation"
                    :disabled="isChangeRoleMode"
                    size="lg"
                    switch
                  />
                </div>
                <div
                  v-if="showExtraParameters"
                  class="col-12 col-sm-6 col-lg-4 col-xl-2 mb-4"
                >
                  <p class="m-0">
                    {{ $t('appointmentUser') }}
                  </p>
                  <b-form-checkbox
                    v-model="model.isAppointmentUser"
                    :disabled="isChangeRoleMode"
                    size="lg"
                    switch
                  />
                </div>
                <div class="col-12 col-sm-6 col-lg-4 col-xl-2 mb-4">
                  <p class="m-0">
                    {{ $t('activeAccount') }}
                  </p>
                  <b-form-checkbox
                    v-model="model.isActive"
                    :disabled="!isEditMode"
                    size="lg"
                    switch
                  />
                </div>
                <div
                  v-if="isEditMode || isChangeRoleMode"
                  class="col-12 col-sm-6 col-lg-4 col-xl-2 mb-4"
                >
                  <p class="m-0">
                    {{ $t('blockedAccount') }}
                  </p>
                  <b-form-checkbox
                    v-model="model.isBlocked"
                    :disabled="isChangeRoleMode || !model.isActive"
                    size="lg"
                    switch
                  />
                </div>
                <div
                  v-if="isEditMode"
                  class="col-12 col-sm-6 col-lg-4 col-xl-2 mb-4"
                >
                  <p class="m-0">
                    {{ $t('activateInternalChat') }}
                  </p>
                  <b-form-checkbox
                    :checked="model.enableInternalChat"
                    class="danger-checkbox"
                    size="lg"
                    switch
                    @change="onActivateInternalChatChange($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'usersList' })"
        :text="$t('cancel')"
      />

      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="isChangeRoleMode ? changeRole() : saveUser()"
      >
        <i class="far fa-save" />
        {{ $t(isChangeRoleMode ? 'changeRole' : 'save') }}
      </button>

      <button
        v-if="!isChangeRoleMode"
        class="btn btn-primary ml-2"
        @click="saveUser(true)"
      >
        <i class="far fa-arrow-alt-circle-right" /> {{ $t('saveContinue') }}
      </button>
    </div>
    <confirmation-modal
      v-model="showChatConfirmationModal"
      text="youWantToActivateInternalChat"
      @on-confirm="showChatConfirmationModal = false"
      @on-reject="rejectChat()"
    />
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import BaseInput from '@/components/BaseInput.vue';
import SystemRoles from '@/constants/SystemRoles';
import { getAllCarriers as _getAllCarriers } from '@/services/CarrierService';
import { getSecurityProfileList as _getSecurityProfileList } from '@/services/SecurityProfileService';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { 
  getLocationList as _getLocationList,
  haveCommunicationModuleEnabled as _haveCommunicationModuleEnabled
 } from '@/services/LocationService';
import { createUser as _createUser } from '@/services/UserService';
import { changeUserToOfficeAccount as _changeUserToOfficeAccount } from '@/services/UserService';
import ConfirmationModal from '@/components/basics/modal/ConfirmationModal.vue';

export default {
  name: 'User',
  components: {
    SubHeader,
    BaseInput,
    ConfirmationModal,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isChangeRoleMode: {
      type: Boolean,
      default: false,
    },
    isAdministrator: {
      type: Boolean,
      default: false,
    },
    lastSecurityProfileId: {
      type: Number,
      default: null,
    },
    roles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      collections: {
        securityProfiles: [],
        phoneProviders: [],
        companies: [],
        locations: [],
      },
      showChatConfirmationModal: false,
      localUserAdministrator: SystemRoles.localuseradministrator,
      haveCommunicationModule: false
    };
  },
  computed: {
    showExtraParameters() {
      return (
        (this.roleHasOffice &&
          this.model.roleId !== this.localUserAdministrator) ||
        (this.model.roleId === this.localUserAdministrator &&
          !!this.model.companyId &&
          !!this.model.locationConfigurationId) ||
        this.isChangeRoleMode
      );
    },
    passwordRequired() {
      return (
        (!this.isChangeRoleMode && !this.isEditMode) ||
        (this.isEditMode &&
          this.lastSecurityProfileId !== this.model.securityProfileId)
      );
    },
    roleHasOffice() {
      if (!this.model.roleId) return false;
      const role = this.roles.find((role) => role.id === this.model.roleId);
      return role && role.hasOffice;
    },
    showLocationForm() {
      return !this.isEditMode && this.roleHasOffice;
    },
  },
  watch: {
    'model.roleId': {
      async handler() {
        if (!this.roleHasOffice && this.roles.length) {
          this.model.companyId = null;
          this.model.locationConfigurationId = null;
          return;
        }

        if (!this.collections.companies.length && !this.isEditMode) {
          await _getCompanies()
            .then((response) => {
              this.collections.companies = response.data || [];
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        }
      },
    },
    async 'model.companyId'(value) {
      this.collections.locations = [];
      if (!value) return;
      await _getLocationList(value)
        .then((response) => {
          this.collections.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async 'model.locationConfigurationId'(value) {
      if (!value) return;

      await _haveCommunicationModuleEnabled(value).then(({ data }) => {
      this.haveCommunicationModule = data;
    });
    },
    'model.isActive'(value) {
      if (!value) this.model.isBlocked = true;
    },
  },
  methods: {
    onActivateInternalChatChange(value) {
      this.model.enableInternalChat = value;
      if (value) this.showChatConfirmationModal = true;
    },
    rejectChat() {
      this.showChatConfirmationModal = false;
      this.model.enableInternalChat = false;
    },
    async loadInitialFilters() {
      await _getAllCarriers()
        .then((response) => {
          this.collections.phoneProviders = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      await _getSecurityProfileList()
        .then((response) => {
          this.collections.securityProfiles = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChange() {
      this.model.locationConfigurationId = null;
    },
    async changeRole() {
      if (!(await this.$refs.userForm.validate())) return;

      let payload = {
        userId: this.model.userId,
        roleId: this.model.roleId,
        companyId: this.model.companyId,
        locationConfigurationId: this.model.locationConfigurationId,
      };

      await _changeUserToOfficeAccount(payload)
        .then(() => this.$router.push({ name: 'usersList' }))
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async saveUser(next) {
      if (!(await this.$refs.userForm.validate())) return;

      await _createUser(this.model)
        .then((response) => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          if (!this.model.userId) {
            this.$router.push({
              name: next ? 'userEditPermissions' : 'userEdit',
              params: { id: response.data },
            });
          } else {
            if (next) this.$emit('go-to', 1);
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
  async mounted() {
    this.loadInitialFilters();    
  },
};
</script>
