<template>
  <div class="mt-5 container-fluid">
    <step-wizard
      class="mb-3"
      :step="stepIndex"
      :tabs="tabs"
      @OnStepChange="onStepChange($event)"
    />
    <ul id="tab-controls" class="nav nav-pills" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive(0) }"
          @click.prevent="onStepChange(0)"
        />
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive(1) }"
          @click.prevent="onStepChange(1)"
        />
      </li>
    </ul>

    <div id="tab-container">
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(0) }"
        role="tabpanel"
      >
        <create
          class="my-3"
          :model="model"
          :is-edit-mode="isEditMode"
          :is-change-role-mode="isChangeRoleMode"
          :is-administrator="isAdministrator"
          :last-security-profile-id="lastSecurityProfileId"
          :roles="roles"
          @go-to="onStepChange($event)"
          @submitted="$refs['assign-permissions-ref'].loadPermissions()"
        />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(1) }"
        role="tabpanel"
      >
        <AssignPermissions
          v-if="model.userId"
          ref="assign-permissions-ref"
          class="my-3"
          :user-id="model.userId"
          @go-to="onStepChange($event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import StepWizard from '@/components/StepWizard';
import Create from './Create';
import AssignPermissions from './AssignPermissions';
import { getUserById as _getUserById } from '@/services/UserService';
import { getRoleList as _getRoleList } from '@/services/RoleService';
import SystemRoles from '@/constants/SystemRoles';
import { mapState } from 'vuex';
import { modulesName } from '@/store';

export default {
  name: 'UserMaster',
  components: {
    StepWizard,
    Create,
    AssignPermissions,
  },
  data() {
    return {
      stepIndex: 0,
      isEditMode: false,
      isChangeRoleMode: false,
      isAdministrator: false,
      lastSecurityProfileId: null,
      roles: [],
      model: {
        userId: null,
        userName: null,
        password: null,
        firstName: null,
        paternalLastName: null,
        maternalLastName: null,
        role: null,
        securityProfileId: null,
        email: null,
        phone: null,
        phoneProviderId: null,
        specialId: null,
        hasTempPassword: false,
        canReceiveappointmentconfirmation: false,
        isAppointmentUser: false,
        isActive: true,
        isBlocked: false,
        roleId: null,
        companyId: null,
        locationConfigurationId: null,
        roleHasOffice: false,
        enableInternalChat: false,
        virtualPhoneExtension: null,
      },
    };
  },
  computed: {
    ...mapState('$_user', ['profile']),
    tabs() {
      const _tabs = [
        {
          name: this.$t('usermaintenance'),
          icon: 'fas fa-plus',
          disabled: false,
        }
      ];

      const { roleId } = this.$store.getters[`${modulesName.userModuleName}/fullProfile`];

      if(roleId === SystemRoles.localuseradministrator){
        return _tabs;
      }

      return [
        ..._tabs,
        {
          name: this.$t('assignPermission'),
          icon: 'far fa-lock',
          disabled: !this.isEditMode,
        },
      ];
    },
  },
  watch: {
    '$route.name'() {
      this.initialize();
    },
  },
  methods: {
    isActive(step) {
      return this.stepIndex == step;
    },
    onStepChange(step) {
      this.stepIndex = step;
    },
    async initialize() {
      this.isAdministrator = this.profile.roleId === SystemRoles.administrator;
      switch (this.$route.name) {
        case 'userEdit':
          this.isEditMode = true;
          break;
        case 'userEditPermissions':
          this.isEditMode = true;
          this.stepIndex = 1;
          break;
        case 'userChangeRole':
          this.isChangeRoleMode = true;
          break;
      }

      if (this.$route.name !== 'userCreate') {
        const userId = this.$route.params.id;
        if (!userId) return this.$router.push({ name: 'usersList' });

        await _getUserById(userId)
          .then((response) => {
            this.model = response.data;
            if (
              this.model.roleId === SystemRoles.administrator &&
              !this.isAdministrator
            )
              this.$router.push({ name: 'usersList' });

            this.lastSecurityProfileId = this.model.securityProfileId;
          })
          .catch(() => this.$router.push({ name: 'usersList' }));
      }

      await _getRoleList()
        .then((response) => {
          let roles = response.data || [];

          this.roles = roles.filter((role) => {
            if (this.isEditMode) {
              return this.model.roleHasOffice && !!this.model.companyId
                ? role.hasOffice
                : !role.hasOffice || role.id === this.localUserAdministrator;
            } else if (this.isChangeRoleMode) return role.hasOffice;

            else {
              if (
                !this.isAdministrator &&
                role.id === SystemRoles.administrator
              )
                return false;
              return true;
            }
          });
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      if (this.isChangeRoleMode) {
        if (this.model.companyId)
          return this.$router.push({ name: 'usersList' });
        this.model.roleId = SystemRoles.clientuser;
      }
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .nav,
.nav-pills {
  display: none;
}
.tab-pane {
  display: none;
}
.tab-pane.active.show {
  display: block;
}
</style>
