<template>
  <div class="row">
    <content-header :title="$t('permissions')" />
    <div class="col-md-12 text-right">
      <button class="btn btn-primary float-left" @click="$emit('go-to', 0)">
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>
      <button
        class="btn btn-outline-primary"
        @click="$emit('on-submit', false)"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
      <button class="btn btn-primary ml-2" @click="$emit('on-submit', true)">
        <i class="far fa-arrow-alt-circle-right" />
        {{ $t('save') }} & {{ $t('finalize') }}
      </button>
    </div>
    <div class="col-md-12 mt-3">
      <div class="row">
        <div class="col-xl-6 col-lg-4">
          <slot></slot>
        </div>
        <div
          class="col-lg-8 col-xl-6"
          v-on:keyup.enter="onSearch()"
        >
          <button class="btn btn-primary float-right ml-3" @click="onSearch()">
            <i class="far fa-search" /> {{ $t('search') }}
          </button>
          <base-input
            class="float-right"
            style="width: 80%"
            v-model="search"
          />
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="main-page-container mt-3">
        <div class="row">
          <div class="col-md-12 text-center my-3 text-capitalize">
            <slot name="legend"></slot>
          </div>
          <div class="col-md-12" style="margin-top: -35px">
             <b-form-checkbox
          :checked="selectedAllPermissions"
          :indeterminate="selectedSomePermissions"
          @change="toggleAll()"
          class="ml-3"
          :disabled="disabled"
        ></b-form-checkbox>
        <hr />
          </div>
        </div>

        <div class="checkbox" :style="{'border-left': selectedColorMark(permission)}" v-for="(permission, i) in permissions" :key="i">
          <b-form-checkbox
            v-model="permission.selected"
            :disabled="permission.disabled"
          >
            <b>{{ permission.name }}</b>
          </b-form-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contentHeader from '@/components/Header';
import BaseInput from '@/components/BaseInput.vue';

export default {
  name: 'Permissions',
  components: {
    contentHeader,
    BaseInput,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    configuredColor: {
      type: String,
      default: '#bbbbbb',
    },
    selectedColor: {
      type: String,
      default: '#bbbbbb',
    },
  },
  data() {
    return {
      search: null,
      permissions: [],
    };
  },
  watch: {
    value() {
      this.onSearch();
    },
  },
  computed: {
    selectedAllPermissions() {
      return (
        this.permissions.filter((x) => x.selected).length ===
        this.permissions.length && this.value.length > 0
      );
    },
    selectedSomePermissions() {
      return (
        this.permissions.filter((x) => x.selected).length > 0 &&
        !this.selectedAllPermissions
      );
    },
    selectedColorMark() {
      return (permission) => {
        if (permission.configured && permission.selected) return `5px solid ${this.configuredColor}`;
        else if (permission.selected) return `5px solid ${this.selectedColor}`;
        return '5px solid #bbbbbb'
      }
    }
  },
  methods: {
    onSearch() {
      this.permissions = this.value.filter((x) => {
        if (!this.search) return true;
        return x.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    toggleAll() {
      const selected = !this.selectedAllPermissions;
      this.permissions.forEach((permit) => {
        if (!permit.disabled) permit.selected = selected;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  background-color: #efefef;
  padding: 10px;
  border-radius: 5px;
  border-left: 5px solid #bbbbbb;
  margin-bottom: 5px;
  font-size: 18px;
}
</style>
