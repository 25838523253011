<template>
  <Permissions :value="permissions" @go-to="$emit('go-to', $event)" @on-submit="onSubmit($event)" />
</template>

<script>
import Permissions from '@/components/Permissions';
import { getUserAndRolePermissionsList as _getUserAndRolePermissionsList } from '@/services/UserService';
import { addUserToPermissions as _addUserToPermissions } from '@/services/UserService';

export default {
  name: 'AssignPermission',
  components: {
    Permissions
  },
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      permissions: []
    }
  },
  mounted() {
    this.loadPermissions();
  },
  methods: {
    onSubmit(finalize) {
      const permissions = this.permissions.filter(x => !x.disabled && x.selected).map(x => x.id);
      _addUserToPermissions({userId: this.userId, permissions}).then(() => {
        this.ShowSuccessSaveToast();
        if (finalize) this.$router.push({ name: 'usersList' });
      })
    },
    loadPermissions(){
      _getUserAndRolePermissionsList(this.userId)
      .then(response => {
        this.permissions = response.data;
      })
    }
  }
};
</script>
