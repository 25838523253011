<template>
  <div :id="id">
    <label v-if="label" :class="labelClass">{{ label }}</label>
    <div class="position-relative formatted-text">
      <label>{{
        value != undefined && value != null && value != ''
          ? formatedText
          : emptyText
      }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInputDisplay',
  meta: {
    description: 'Componente para mostrar datos',
    slots: {
      default: {
        type: 'component',
        valid: [],
      },
    },
  },
  props: {
    id: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: '',
      meta: {
        description: 'Control para mostrar texto ',
        example: 'Texto requerido',
      },
    },
    labelClass: {
      type: String,
      default: '',
      meta: {
        description: 'Clases para el label',
        example: 'Texto requerido',
      },
    },
    value: {
      type: [String, Number],
      default: '',
      meta: {
        description: 'Parámetro de entrada en el input',
        example: 'Texto requerido',
      },
    },
    maskType: {
      type: String,
      default: '',
      meta: {
        description: 'Máscaras del texto',
        example: 'Texto requerido',
      },
    },
    emptyText: {
      type: String,
      default: () => '-',
    },
    fixedText: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    isValid() {
      return !this.error && this.dirty;
    },
    formatedText() {
      if (this.fixedText != null) return this.fixedText;
      else
        return this.value != undefined && this.value != null && this.value != ''
          ? this.formatText(this.value)
          : this.emptyText;
    },
  },
  methods: {
    formatText(value) {
      //conditionals to remove masks from text
      if (value != undefined && value != null && value != '') {
        if (this.maskType == 'Phone') {
          return value
            .replace(/[^0-9]/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        } else if (this.maskType == 'Price') {
          if (!isNaN(value)) {
            let val = (value / 1).toFixed(2);
            return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '');
          }
        }
      }

      return value;
    },
  },
};
</script>

<style scoped>
.input-display {
  margin-left: 1rem;
}

.input-display label:first-child {
  margin-bottom: 0;
}

.input-display label:last-child {
  font-weight: normal;
}
</style>
