<template>
  <b-card border-variant="light" class="py-0 listview"
    :class="{
      grey: !value.isActive || value.isBlocked,
      lightgreen: value.isActive && !value.isBlocked
    }">
    <b-row>
      <b-col class="col-12 col-sm-12 col-md-4 col-lg-3">
        <div class="cell">
          <label>{{ $t('userName') }}: </label>
          <span class="red">{{ value.userName }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('name') }}: </label>
          <span>{{ value.firstName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('paternalLastName') }}: </label>
          <span>{{ value.paternalLastName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('maternalLastName') }}: </label>
          <span>{{ value.maternalLastName || '-' }}</span>
        </div>
      </b-col>

      <b-col class="col-12 col-sm-12 col-md-4 col-lg-4">
        <div class="cell">
          <label>{{ $t('roles') }}: </label>
          <span>{{ parseRoleNames(value.roleNames) || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('securityProfile') }}: </label>
          <span>{{ value.securityProfileName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('email') }}: </label>
          <span>{{ value.email || '-' }}</span>
        </div>
      </b-col>

      <b-col class="col-12 col-sm-12 col-md-4 col-lg-3">
        <div class="cell">
          <label>{{ $t('mobile') }}: </label>
          <base-input-display v-model="value.phoneNumber" mask-type="Phone" />
        </div>
        <div class="cell">
          <label>{{ $t('provider') }}: </label>
          <span>{{ value.phoneProviderName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('active') }}: </label>
          <span>{{ $t(value.isActive ? 'yes' : 'no') }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('blocked') }}: </label>
          <span>{{ $t(value.isBlocked ? 'yes' : 'no') }}</span>
        </div>
      </b-col>
      <b-col class="col-12 col-sm-12 col-md-12 col-lg-2">
        <action-button :actions="actions" :text="$t('actions')" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';
import BaseInputDisplay from '@/components/BaseInputDisplay';
import SystemRoles from '@/constants/SystemRoles';
import { modulesName } from '@/store';

export default {
  name: 'UserSearchResult',
  components: {
    ActionButton,
    BaseInputDisplay,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    actions() {
      return [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('edit'),
        },
        {
          label: this.$t('permissions'),
          iconClass: 'far fa-lock',
          iconColor: '#EB6D00',
          action: () => this.$emit('assign-permissions'),
          visible: () => {
            const { roleId } = this.$store.getters[`${modulesName.userModuleName}/fullProfile`];

            if(roleId === SystemRoles.localuseradministrator) return false;

            return true;
          }
        },
        {
          label: this.$t('assignOffice'),
          iconClass: 'far fa-plus-square',
          iconColor: '#24852E',
          action: () => this.$emit('assign'),
          visible: () => !!this.value.companyId,
        },
        {
          label: this.$t('assignAppointmentOffice'),
          iconClass: 'far fa-plus-square',
          iconColor: '#24852E',
          action: () => this.$emit('assign-appointment'),
          visible: () => !!this.value.companyId,
        },
        {
          label: this.$t('changeRoleToOffice'),
          iconClass: 'far fa-repeat-alt',
          iconColor: '#FF8105',
          action: () => this.$emit('change'),
          visible: () => this.value.roleId.includes(SystemRoles.webuser),
        },
        {
          label: this.$t(this.value.isBlocked ? 'unlock' : 'block'),
          iconClass: this.value.isBlocked ? 'far fa-user-unlock' : 'far fa-user-lock',
          iconColor: this.value.isBlocked ? '#FF8105' : '#FF0000',
          action: () => this.$emit('block'),
          visible: () => this.value.isActive,
        },
        {
          label: this.$t('history'),
          iconClass: 'far fa-history',
          iconColor: '#FF8105',
          action: () => this.$emit('history'),
        },
      ];
    }
  },
  methods: {
    parseRoleNames(roleNames) {
      if (!roleNames) return '-';

      const rolesList = roleNames.toLowerCase().replace(' ', '').split(',');
      const result = [];

      rolesList.forEach((role) => {
        result.push(this.$t('role' + role));
      });

      return result.join(', ');
    },
    isRoleEnabled(role) {
      return (
        role == SystemRoles.administrator ||
        role == SystemRoles.webuser ||
        role == SystemRoles.salesuser ||
        role == SystemRoles.systemadmin ||
        role == SystemRoles.sponsoruser ||
        role == SystemRoles.localuseradministrator
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.listview .cell {
  clear: left;
}

.listview .cell label {
  float: left;
  font-weight: bold;
}

.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}

  .listview.grey {
    border: 1px solid #d0cccc !important;
    background-color: #f8f8f8;
    ::v-deep .c-actionButton {
      border: 1px solid #d0cccc !important;
    }
  }

  .listview.lightgreen {
    border: 1px solid #a4afa5 !important;
    background-color: #f2fff2;
    ::v-deep .c-actionButton {
      border: 1px solid #a4afa5 !important;
    }
  }

  .listview.red {
    border: 1px solid #fad4d4 !important;
    background-color: #fdf5f5;
    ::v-deep .c-actionButton {
      border: 1px solid #fad4d4 !important;
    }
  }
</style>
