export default {
  ConvertToByteArray (documentContent) {
    const byteChars = atob(documentContent);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    return byteArray;
  },
  async ConvertToBlob (documentFile) {
    const byteArray = this.ConvertToByteArray(documentFile.content);

    const blob = new Blob([byteArray], {
      type: documentFile.contentType
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = documentFile.filename;
    link.click();
  },
  async ConvertToBlobToOpen (documentFile) {
    const byteArray = this.ConvertToByteArray(documentFile.content);
    const blob = new Blob([byteArray], {
      type: documentFile.contentType
    });
    const link = document.createElement('a');

    link.download = documentFile.filename;
    link.href = window.URL.createObjectURL(blob);
    link.target='_blank';
    link.click();
  }
};
