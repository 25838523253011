import { apiTurnos } from '@/core/api/';

export const getSecurityProfileList = () => apiTurnos.get('api/securityprofile/list');

export const getSecurityProfiles = (payload) => apiTurnos.get('/api/securityprofile/search', { params: { ...payload } });

export const getById = (securityProfileId) => apiTurnos.get(`api/securityprofile/${securityProfileId}`);

export const createSecurityProfile = payload => apiTurnos.post('api/securityProfile', payload);

export const updateSecurityProfile = payload => apiTurnos.put('api/securityProfile', payload);