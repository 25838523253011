import { apiTurnos } from '@/core/api/';

export const getRoleList = applicationId => {
  return apiTurnos.get(`api/role/list?applicationId=${applicationId || ''}`);
};

export const getRoles = (payload) => apiTurnos.get('/api/role/search', { params: { ...payload } });

export const getPermissionsList = (roleId) => {
  return apiTurnos.get(`api/role/${roleId}/permissions`);
}

export const addPermissions = (payload) => apiTurnos.post(`api/role/${payload.roleId}/permissions`, payload.permissions);

export const getById = (roleId) => apiTurnos.get(`api/role/${roleId}`);

export const manageRole = payload => {
  return apiTurnos[payload.roleId ? 'put' : 'post']('api/role', payload);
};

export const deleteRole = (payload) => apiTurnos.delete(`/api/role/${payload}`);

export const getAlertRoles = () => apiTurnos.get('/api/role/alerts');

