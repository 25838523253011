<template>
  <validation-observer
    tag="div"
    class="row mt-3"
    ref="massCreateUserRef"
    novalidate
  >
    <div class="col-md-12 mb-3 text-center">
      <h6 class="text-uppercase">
        {{ $t('massCreateUsers') }}
      </h6>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-9">
          <validation-provider rules="required">
            <base-input
              v-model="currentDocument.Filename"
              :disabled="true"
              :label="$t('usertemplate')"
            />
          </validation-provider>
        </div>
        <div class="col-md-3 pt-1 text-right">
          <button class="btn btn-primary file-upload mt-4" @click="showUpload">
            <i class="fas fa-search mr-2" /> {{ $t('search') }}
          </button>
          <input
            ref="fileuploadRef"
            accept="csv"
            class="d-none"
            type="file"
            @change="handleFileChange"
          />
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-4 mb-3">
      <base-filled-button
        class="mx-0"
        bg-color="#707070"
        icon-class="far fa-times-circle"
        :on-click="() => $emit('close')"
        :text="$t('cancel')"
      />
      <b-button
        class="text-nowrap float-right"
        variant="primary"
        @click="uploadDocuments"
      >
        <i class="fal fa-upload" /> {{ $t('loadcsv') }}
      </b-button>
    </div>
  </validation-observer>
</template>
<script>
import BaseInput from '@/components/BaseInput.vue';
import { massCreateUsers as _massCreateUsers } from '../../../services/UserService';
import _generalApi from '@/modules/app/api.js';
import { getLocationList as _getLocationList } from '@/services/LocationService';

export default {
  name: 'MassCreateUser',
  components: {
    BaseInput,
  },
  data() {
    return {
      collections: {
        companies: [],
        locations: [],
      },
      model: {
        companyId: null,
        locationId: null,
      },
      currentDocument: {
        Filename: null,
        IsRequired: null,
        Content: null,
      },
    };
  },
  watch: {
    async 'model.companyId'(value) {
      this.model.locationId = null;
      this.collections.locations = [];
      if (!value) return;
      await _getLocationList(value)
        .then((response) => {
          this.collections.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
  methods: {
    handleFileChange(e) {
      const isValid = this.$refs.massCreateUserRef.validate();

      if (isValid) {
        let docContent = e.target.files[0];
        this.currentDocument.Content = docContent;
        this.currentDocument.Filename = docContent.name;
      }
    },
    async uploadDocuments() {
      if (this.currentDocument.Content == null) {
        this.ShowErrorToast(this.$t('fileRequired'));
        return;
      }

      var formData = new FormData();
      formData.append('file', this.currentDocument.Content);

      await _massCreateUsers(formData)
        .then((resp) => {
          _generalApi.ConvertToBlob(resp.data);

          this.$emit('close');
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    showUpload() {
      this.$refs.fileuploadRef.click();
    },
  }
};
</script>
<style lang="scss" scoped>
#user-mass-create-modal {
  margin-bottom: 0;
  h6 {
    color: $color-primary;
  }
}
::v-deep label {
  color: $color-font-secondary !important;
  font-size: 20px;
}

.file-upload {
  color: white !important;
  font-size: 16px;
}
</style>
