<template>
  <custom-modal
    ref="confirmation-modal-ref"
    :no-close-on-back-drop="true"
    :no-close-on-esc="true"
    size="md"
  >
    <validation-observer ref="form" novalidate>
      <div class="back-container text-center">
        <i
          class="fal fa-exclamation-circle back-container__icon fa-3x"
          style="color: #ff8105"
        />

        <div class="back-container__title text-center mt-3">
          {{ $t(text) }}
        </div>
        <div class="d-flex flex-row mt-4">
          <button
            class="btn btn-secondary mx-4 flex-grow-1"
            type="button"
            @click="$emit('on-reject')"
          >
            {{ $t(rejectionText) }}
          </button>

          <button
            class="btn btn-primary mx-4 flex-grow-1"
            type="button"
            @click="$emit('on-confirm')"
          >
            {{ $t(confirmationText) }}
          </button>
        </div>
      </div>
    </validation-observer>
  </custom-modal>
</template>

<script>
import CustomModal from '@/components/basics/modal/CustomModal.vue';

export default {
  name: 'ConfirmationModal',
  components:  {
    CustomModal
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    confirmationText: {
      type: String,
      default: 'yes',
    },
    rejectionText: {
      type: String,
      default: 'no',
    },
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    value(val) {
      this.$refs['confirmation-modal-ref'][val ? 'show' : 'hide']();
    },
  },
};
</script>
